import {useEffect, useState} from 'react';

import fetchAllExamSchedules, {ApiResult} from '../api/toeicDates';
import {createOptions, Option} from '../utils/select';

export const useToeicDates = ({year, month}: {year: number, month: number}) => {
  const [responseResult, setResponseResult] = useState<ApiResult>();
  const [error, setError] = useState(null);
  const [toeicDates, setToeicDates] = useState({years: [], months: [], dates: {}} as ToeicDates);

  const [yearOptions, setYearOptions] = useState([] as Option[]);
  const [monthOptions, setMonthOptions] = useState([] as Option[]);
  const [dayOptions, setDayOptions] = useState([] as Option[]);

  useEffect(() => {
    const getToeicDates = async () => {
      const response = await fetchAllExamSchedules();

      if (response.result === 'success') {
        setToeicDates(response.data);
        setResponseResult(response.result);
        setYearOptions(createOptions(response.data.years, '년'));
        setMonthOptions(createOptions(response.data.months, '월'));
      }

      if (response.result === 'failure') {
        setError(response.error);
      }
    };

    getToeicDates();
  }, []);

  useEffect(() => {
    if (responseResult === 'success' && !isNaN(year) && !isNaN(month)) {
      const dates = toeicDates.dates[year][month - 1];
      setDayOptions(createOptions(dates, '일'));
    }
  }, [year, month, toeicDates.dates, responseResult]);

  return {
    yearOptions,
    monthOptions,
    dayOptions,
    error
  };
};

type Years = number[];
type Months = number[];
type Dates = Record<string, number[][]>;
export interface ToeicDates {
  years: Years;
  months: Months;
  dates: Dates;
}
