import React, {useState} from 'react';
import Select from 'react-select';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import { useToeicDates } from '../hooks/useToeicDates';
import padZeroToFront from '../utils/padZeroToFront';
import {Option} from '../utils/select';

const Home: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [year, setYear] = useState(NaN);
  const [month, setMonth] = useState(NaN);
  const [day, setDay] = useState(NaN);
  const {id} = queryString.parse(location.search);

  const {yearOptions, monthOptions, dayOptions, error} = useToeicDates({year, month});

  const clearInputs = () => {
    setMonth(NaN);
    setDay(NaN);
  };
  const handleYearChange = ({value}: {value: number}) => {
    setYear(value);
    clearInputs();
  };
  const handleMonthChange = ({value}: {value: number}) => setMonth(value);
  const handleDayChange = ({value}: {value: number}) => setDay(value);
  const handleSubmit = () => {
    const date = `${year}-${padZeroToFront(month, 2)}-${padZeroToFront(day, 2)}`;
    history.push(`/check?${queryString.stringify({date, id})}`);
  };

  if (error) {
    window.alert('에러가 발생했습니다. 잠시 후 다시 시도해주세요.');
  }

  return (
    <div className="content">
      <div className="content-header">
        등록하실 토익 성적일을 <br />
        입력해주세요.
        </div>
      <div className="content-inputs">
        <div className="selector-year">
          <Select
            className="selector"
            value={yearOptions.filter(({value}) => year === value)}
            onChange={option => handleYearChange(option as Option)}
            options={yearOptions}
          />
        </div>
        <br />
        <div className="selector-month">
          <Select
            className="selector"
            value={monthOptions.filter(({ value }) => month === value)}
            onChange={option => handleMonthChange(option as Option)}
            options={monthOptions}
          />
        </div>
        <br />
        <div className="selector-day">
          <Select
            className="selector"
            value={dayOptions.filter(({ value }) => day === value)}
            isDisabled={!year || !month}
            onChange={option => handleDayChange(option as Option)}
            options={dayOptions}
          />
        </div>
        <br />
      </div>
      <div>
        <button
          className="button"
          disabled={!year || !month || !day}
          onClick={handleSubmit}
        >
          성적 제출 가능 여부 확인
        </button>
      </div>
    </div>
  )
};

export default Home;
