import useSWR from 'swr'
import queryString from 'query-string';

const GET_IS_TARGET_PATH = 'https://veoaboiuc9.execute-api.ap-northeast-2.amazonaws.com/prod';

const fetcher = async (path: string) => {
  const response = await fetch(GET_IS_TARGET_PATH + path);
  const {ack} = await response.json();

  return ack === 1;
};

const useIsTarget: UseIsTarget = (id, date)  => {
  const {data, error, isValidating} = useSWR(`/?${queryString.stringify({id, date})}`, fetcher);

  return {isTarget: data, error, isLoading: isValidating};
};

export default useIsTarget;

interface UseIsTarget {
  (id: string | string[], date: string | string[]): UseIsTargetReturnType
}
interface UseIsTargetReturnType {
  isTarget: boolean | undefined;
  error?: Object;
  isLoading?: boolean;
}
