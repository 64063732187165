import React from 'react';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import LoadingIcon from './LoadingLottie';
import useIsTarget from '../hooks/useIsTarget';

const renderLoading = () => {
  return (
    <div className="content">
      <div className="content-icon">
        <LoadingIcon />
      </div>
      <div className="content-header">
        서버에서 문제풀이 기록을
        <br /> 확인중입니다...
      </div>
    </div>
  );
};

const Check: React.FC = () => {
  const location = useLocation();
  const {id, date} = queryString.parse(location.search);
  const {isTarget, isLoading} = useIsTarget(id!, date!);

  const registerScore = () => {
    if (window) {
      window.location.href = `https://riiid.typeform.com/to/wx2Ily${location.search}`;
    }
    return null;
  };

  if (isLoading) return renderLoading();
  if (isTarget) return registerScore();

  return (
    <div className="content warning-content">
      <div className="content-warning-icon">!</div>
      <div className="content-header warning-header">
        죄송합니다.
        <br />
        해당 성적은 산타토익의 토익 점수 수집
        <br />
        <div>
          대상<sup>*</sup>에 해당되지 않습니다.
        </div>
      </div>
      <div className="content-detail">
        * 토익 시험일 기준 14일 이내에 10문제 이상을 푸신 유저분들
      </div>
    </div>
  );
}

export default Check;
