import React from 'react';
import {BrowserRouter as Router, Route} from 'react-router-dom';

import Home from '../Home';
import Check from '../Check';
import './style.css';

const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <Route exact path="/" component={Home} />
        <Route path="/check" component={Check} />
      </div>
    </Router>
  );
};

export default App;
