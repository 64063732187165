import React from 'react';
import Player from 'react-lottie';
import loadable from 'react-loadable';

const LoadingAnimation = loadable({
  loader: () => import('../lotties/loading.json'),
  render: (animationData) => (
    <Player
      width={100}
      height={100}
      options={{
        loop: true,
        autoplay: true,
        animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }}
    />
  ),
  loading: () => <div style={{ width: 100, height: 100 }} />
});

export default LoadingAnimation;
