import { ToeicDates } from "../hooks/useToeicDates";

export enum ApiResult {
  success = 'success',
  failure = 'failure'
}
type ReturnTypeSuccess = {
  result: ApiResult.success,
  data: ToeicDates,
  error: null
}
type ReturnTypeFailure = {
  result: ApiResult.failure,
  data: null,
  error: any
}

const GET_ALL_EXAMINATION_SCHEDULES_PATH =
  process.env.REACT_APP_API_ENV === 'production' ?
  'https://api.vida.riiid.co/api/all_examination_schedules' : 'https://dev.api.vida.riiid.co/api/all_examination_schedules';

const fetchAllExamSchedules = async (): Promise<ReturnTypeSuccess | ReturnTypeFailure> => {
  try {
    const response = await fetch(GET_ALL_EXAMINATION_SCHEDULES_PATH);

    return {
      result: ApiResult.success,
      data: await response.json() as ToeicDates,
      error: null
    };
  } catch (error) {
    console.error('Fetch Exam Api Error: ', error);

    return {
      result: ApiResult.failure,
      data: null,
      error
    };
  }
};

export default fetchAllExamSchedules;
